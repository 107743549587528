import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
// import Encuesta from '../components/forms/encuesta'
import Encuesta from '../components/pages/Encuesta'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const IndexPage = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      companyName={pageContext.company}
      pageContext={pageContext}
      location={location}
      gradient
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <Encuesta pageContext={pageContext} />
        </>
      )}
    />
  </GlobalFooterProvider>
)

export default IndexPage
