import { css } from '@emotion/react'
import React, { Component } from "react"
import { CardElevation, Encuesta } from '@findep/mf-landings-core'
import { navigate } from "../../helpers/queryNavigate"
import { navigate as navigateGatsby } from "gatsby"

import { CataloguesService } from '../../services/LandingV4'
import { encuestaBFF } from '../../lib/saveData'
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext'
import getTenant from '../../helpers/getTenant'

class EncuestaView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            idPregunta: 0,
            idEncuesta: 0,
            pregunta: '',
        }
        console.log(this.props.pageContext)
        this.eventosalir = this.eventosalir.bind(this)
        this.eventoenvia = this.eventoenvia.bind(this)
    }

    static contextType = GlobalStateFooterContext;

    eventoenvia = async (respuesta) => {
        await encuestaBFF(this.state, respuesta)
        this.props.pageContext.next.path === '/' ? navigateGatsby(this.props.pageContext.next.path) : navigate(this.props.pageContext.next.path)
        sessionStorage.setItem('survey', 'true')
    }

    eventosalir = () => {
        this.props.pageContext.alternatePath === "/" ? navigateGatsby(this.props.pageContext.alternatePath) : navigate(this.props.pageContext.alternatePath)
    }

    async componentDidMount() {        
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let id = flujo?.creditApplicationId

        //GET TENANT        
        const tenantValue = await getTenant(id)
        this.context.setIsDynamic(true)
        this.context.setTenantFooter(tenantValue)

        const catalogos = new CataloguesService()
        const catData = await catalogos.getSurveys(id)
        const { idPregunta, idEncuesta, pregunta } = catData.data[0]
        this.setState({ idPregunta })
        this.setState({ idEncuesta })
        this.setState({ pregunta })
    }

    componentDidUpdate(prevProp, prevState) {

    }


    render() {
        // const { palette } = useTheme()
        // color: ${palette.primary.main} !important;
        const { pregunta } = this.state
        const textHeader = css`
        
        text-align: center
        `

        const encuesta = {
            botonuno: 'SALIR SIN ENVIAR',
            botondos: 'ENVIAR',
            referenciauno: 'Nada probable',
            referenciados: 'Muy probable',
            leyenda: pregunta
        }
        return (
            <>
                <h2 css={textHeader}>Encuesta de satisfacción</h2>
                <CardElevation noPadding>
                    <Encuesta idBtnCero="encuesta" idBtnOne="encuesta" idBtnTwo="encuesta" idBtnThree="encuesta" idBtnFour="encuesta" idBtnFive="encuesta" idBtnSix="encuesta" idBtnSeven="encuesta" idBtnHeight="encuesta" idBtnNine="encuesta" idBtnSalir="encuesta-salir" idBtnEnviar="encuesta-enviar" eventoenvia={this.eventoenvia} eventosalir={this.eventosalir} textpoll={encuesta} />
                </CardElevation>
            </>
        )
    }
}
export default EncuestaView